
/* --- Detail page css --- */
.detail-news-outer{
    display: block;
    padding: 0px;
    flex: 0 1 auto;
    max-width: 770px;
    width: 100%;
}

.detail-news-heading{
    font-size: 24px;
    line-height: 1.6;
    font-weight: 600;
    color: black;
}

.meta-social-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
}

.meta-author{
    display: flex;
    /* margin-top: 10px; */
    align-items: center;
}

.meta-author .meta-author-img{
    width: 40px;
    height: 40px;
    object-fit: contain;
    object-position: center;
    margin-right: 5px;
    border-radius: 50%;
    overflow: hidden;
}

.meta-author .meta-author-data .meta-data-name {
    font-size: 14px;
    font-weight: 600;
    color: black;
    line-height: 1.3;
}

.meta-author .meta-author-data .meta-data-time {
    font-size: 13px;
    font-weight: 400;
    color: #aaaaaa;
    line-height: 1.2;
}

.social_share-bx{
    display: flex;
    flex-wrap: wrap;
    /* margin-top: 15px; */
    align-items: center;
}

.social_share-bx .ssb-lable{
    color: #666;
    font-weight: 500;
    margin-right: 5px;
}

.social_share-bx .ssb-icon{
    font-size: 16px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    padding: 0px;
    margin: 2px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #666666;
    cursor: pointer;
    background-color: #f5f5f5;
    transition: all 0.3s ease-in-out;
}

.social_share-bx .ssb-icon{
    font-size: 16px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    padding: 0px;
    margin: 2px 3px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #666666;
    cursor: pointer;
    background-color: #f5f5f5;
}

.social_share-bx .ssb-icon:hover {
    color: white;
}

.social_share-bx .ssb-icon.fb:hover {
    background-color: #475993;
}

.social_share-bx .ssb-icon.yt:hover {
    background-color: #ff0000;
}

.social_share-bx .ssb-icon.tw:hover {
    background-color: #1da0f2;
}

.social_share-bx .ssb-icon.wa:hover {
    background-color: #44be53;
}

.social_share-bx .ssb-icon.tl:hover {
    background-color: #33a0cd;
}

.social_share-bx .ssb-icon.li:hover {
    background-color: #0077b5;
}

.social_share-bx .ssb-icon.cm:hover {
    background-color: #ce0000;
}

.detail-news-ivio-bx{
    position: relative;
    padding-top: 56%;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 15px;
    box-shadow: 0 4px 10px #ccc !important;
}

.detail-news-ivio-bx > *:not(audio){
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.detail-news-ivio-bx > audio{
    position: absolute;
    bottom: 10px;
    top: initial;
    left: 50%;
    width: 98%;
    transform: translateX(-50%);
    box-shadow: 0 0 10px #00000078;
    max-height: 54px;
    border-radius: 50px;
}

.ivio-tag{
    width: 100%;
    text-align: center;
    color: #6c6c6c;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
}

.detail-news-text-bx{
    margin-top: 20px;
}

.detail-news-text-bx > *,
.detail-news-text-bx{
    font-size: 17px;
    margin-bottom: 15px;
    color: black;
    display: block;
    text-align: justify;
}

.detail-news-tags{
    margin-top: 15px;
}

.detail-news-tags span{
    padding: 0px 12px;
    color: #aaa;
    border: 1px solid #eee;
    background: #fafafa;
    display: inline-block;
    border-radius: 50px;
    line-height: normal;
    font-size: 14px;
    font-weight: 400;
    margin: 0 5px 5px 0;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.detail-news-tags span:hover{
    color: #d21813;
    border-color: #d21813;
    background: #fef8f8;
}

.detail-news-related{
    margin-top: 40px;
}

.social-fuo{
    margin-top: 25px;
    margin-bottom: 0px;
}

.social-fuo .csl-lable{
    display: none;
}


@media screen and (max-width:992px) {

    .detail-news-outer{
        max-width: 100%;
    }
}

@media screen and (max-width:768px) {

    .detail-news-heading{
        font-size: 20px;
    }

    .detail-news-text-bx > *, .detail-news-text-bx{
        font-size: 16px;
    }

    .meta-social-box{
        flex-direction: column;
        align-items: start;
    }

    .social_share-bx:not(.social-fuo){
        margin-top: 15px;
    }
}