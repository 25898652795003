
/* --- Featured Slider --- */
.featured-main-bx{
    display: flex;
    margin-top: 30px;
}

.featured-main-bx .featured-slider{
    flex: 0 1 calc(100% - 320px);
    max-width: calc(100% - 320px);
    padding-left: 15px;
}

.featured-main-bx .fslideraside{
    padding: 0px 15px 0px 15px;
    flex: 0 0 320px;
}

.featured-item {
    background-color: lightgray;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
    height: 290px;
    display: block;
}

.featured-item-link{
    width: calc(100% - 360px) !important;
    position: relative;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.featured-item-link img.cmn-img-bg{
    width: 100% !important;
    height: 100%;
    display: block;
    object-fit: cover;
    object-position: center;
}

.featured-heading{
    position: absolute;
    right: 0;
    top: 0;
    width: 360px;
    height: 100%;
    z-index: 1;
    padding: 20px 20px 20px 20px;
    background: rgb(0 0 0 / 60%);
    overflow: hidden;
}

.featured-heading > div.fh-img{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url("../img/news-img/ni-(5).jpg");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: cover;
    filter: blur(30px);
    opacity: .5;
    transform: scale(1.5) scaleX(-1);
    background-position: right center;
    z-index: -1;
}

.featured-heading > div.fh-text{
    color: #fff;
    font-size: 20px;
    line-height: 1.6;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.featured-heading > div.fh-sub-text{
    color: #fff;
    font-size: 14px;
    line-height: 1.6;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 10px;
    opacity: 0.7;
    padding-right: 25px;
}

@media screen and (max-width:1200px) {
    .featured-main-bx{
        margin: 0;
    }

    .featured-main-bx .featured-slider {
        flex-basis: 100%;
        max-width: 100%;
        padding-left: 0px;
    }

    .featured-item {
        height: 310px;
    }

    .featured-main-bx .fslideraside{
        display: none;
    }
}

@media screen and (max-width:850px) {
    .featured-item-link {
        width: calc(100% - 300px) !important;
    }
    .featured-heading{
        width: 300px;
    }
}

@media screen and (max-width:768px) {
    .featured-item {
        height: auto;
        padding-top: 56%;
    }

    .featured-item-link {
        width: 100% !important;
    }

    .featured-heading{
        width: 100%;
        padding: 30px 15px 15px 15px;
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 80%);
        height: auto;
        bottom: 0;
        top: inherit;
    }

    .featured-heading > div.fh-text{
        font-size: 18px;
        text-align: center;
    }

    .featured-heading > div.fh-img,
    .featured-heading > div.fh-sub-text{
        display: none;
    }
}

@media screen and (max-width:576px) {
    .featured-heading > div.fh-text{
        font-size: 16px;
    }
}